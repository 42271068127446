<template>
  <v-card fluid>
    <v-snackbar v-model="notify" :timeout="2500" top>
      {{ message }}
    </v-snackbar>
    <v-toolbar flat dark color="primary" max-height="100px">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title> {{ $t("new") }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="submit"> {{ $t("save") }} </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-form ref="boatForm" class="mt-5">
        <v-row>
          <v-col cols="12">
            <v-text-field
              :label="$t('form.name')"
              :rules="rule"
              v-model="input.name"
              hide-details
              outlined
              :dense="full"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="12">
            <auto-complete-users
              :label="$t('form.owner')"
              v-model="input.owner"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              :label="$t('form.model')"
              v-model="input.model"
              hide-details
              outlined
              :dense="full"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              :label="$t('form.make')"
              v-model="input.make"
              hide-details
              outlined
              :dense="full"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              :label="$t('form.length')"
              v-model="input.length"
              hide-details
              outlined
              :dense="full"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              :label="$t('form.port')"
              v-model="input.port"
              hide-details
              outlined
              :dense="full"
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import AutoCompleteUsers from "../../components/appComponents/AutoCompleteUsers.vue";
import { BoatsViewModel } from "../models";
export default {
  components: { AutoCompleteUsers },
  name: "new-boat",
  data: () => ({
    input: new BoatsViewModel(),
    notify: false,
    message: null,
  }),
  computed: {
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    rule() {
      return [(v) => !!v || this.$t("form.required")];
    },
  },
  methods: {
    ...mapActions("entry", ["addItem"]),
    async submit() {
      const valid = this.$refs.boatForm && this.$refs.boatForm.validate();
      if (valid) {
        const {
          name,
          owner: { firstName, lastName },
          model,
          make,
          length,
          port,
        } = this.input;
        const data = { name, owner: { firstName, lastName } };
        model && (data.model = model);
        make && (data.make = make);
        length && (data.length = length);
        port && (data.port = port);
        const response = await this.addItem({ item: "boats", data });
        this.notify = true;
        if (response?.data?.success) {
          this.message = this.$t("form.boatCreated");
        } else {
          this.message = this.$t("form.boatExists");
        }
        this.$emit("close");
      }
    },
    init() {
      this.$refs.boatForm && this.$refs.boatForm.reset();
    },
  },
};
</script>
