const en = {
  login: {
    signIn: "Sign In",
    rememberMe: "Remember me?",
    forgotPwd: "Forgot password?",
    failedPwd3Times: "You have failed 3 times, please reset your password",
    close: "Close",
    haveAccount: "Don't you have an account?",
    reload: "Reload",
    resetPwd: "Reset Password",
    activate: "Activate Account",
    activateMsg: "Please activate your account to confirm your email address",
  },
  signUp: {
    title: "Sign Up",
    haveAccount: "Do you have an account?",
    confirm: "Confirm Password",
    firstName: "First Name",
    lastName: "Last Name",
    privacy: "Privacy",
  },
  forgotPwd: {
    title: "Recover Password",
    subtitle: "Please enter a valid email to recover your password",
    okButton: "Send recovery mail",
    cancelButton: "Go Back",
  },
  activationEmail: {
    title: "Activation Email",
    subtitle: "Please enter your account email to be confirmed",
    okButton: "Send activation email",
    cancelButton: "Go Back",
  },
  statistics: {
    title: "Statistics",
    tags: "TOTAL TAGS",
    releases: "TOTAL RELEASE",
    recaptures: "TOTAL RECAPTURE",
    tagsLastYear: "TAGS LAST YEAR",
    releasesLastYear: "RELEASE LAST YEAR",
    recapturesLastYear: "RECAPTURE LAST YEAR",
    users: "TOTAL USERS",
  },
  dashboard: {
    roleConfirm: {
      title: "What is your role?",
      role: "Role",
    },
    forOthersConfirm: {
      title: "Are you entering records for yourself or for others?",
      own: "Own",
      others: "Others",
    },
    batchUploadConfirm: {
      title: "Submit {type}",
      subtitle:
        "Do you want to submit a {singular} or do you want to use an excel file to import a batch?",
      single: "Submit a {singular}",
      multiple: "Upload multiple at once",
    },
    welcomeMsg: {
      p1: `
            The Billfish Foundation's Tag and Release Program is the largest
            private billfish tagging database in the world, helping to provide
            vital billfish data associated with migration, age, growth, etc. and
            acting as the cornerstone of TBF's work.
        `,
      p2: `
            We recognize that TBF would not be the pillar it is today without
            the sportfishing community. Without the support of thousands of
            anglers, captains and mates all striving toward one conservation
            goal, TBF would not have a base to stand on as we push for various
            billfish management policies. Every fish that is tagged, released,
            recaptured and reported provides us with one more piece to the
            ever-growing billfish puzzle.
        `,
      thanks: "We thank you for your support.",
      team: "-The TBF team",
      dontShowAgain: "Don't show this message again",
    },
    fishingLocations: {
      title: "Fishing Locations",
      disableNavigation: "Disable Navigation",
      enableNavigation: "Enable Navigation",
      setCurrent: "Set Current",
      geolocationNotSupported: "Geolocation is not supported by this device",
      allowGeolocation: "Allow Geolocation permission",
      selectLocation: "Click to select location",
      clearMarker: "Clear Marker",
      help: {
        title: "How the map feature works",
        setMarker: "Set a marker",
        setMarkerDescription:
          'To pin a location in the map or set a marker, you need to click on the map area and a marker/pin will be set. If you want to lock the coordinates of the selected location you just need to click on the "Click to select location" pop up.',
        setCurrent: "Set your current location",
        setCurrentDescription:
          'To set your current location you can click on the "Set Current" button at the top right of the map area.',
        modifyLocation: "Modify the location",
        modifyLocationDescription:
          'To modify the coordinates or the pinned location, you can click on the "Enable Navigation" button at the bottom left of the map area. That will allow you to scroll/zoom and move across the map, and find the wanted location.',
        removeLocation: "Remove the pin or location marker",
        removeLocationDescription:
          'To remove the pin/marker you can click on the "Clear Marker" button at the top right of the map area.',
      },
    },
    fishingHistory: {
      title: "Fishing History",
      own: "Own",
      others: "Others",
      forOthers: "Entered for others",
      number: "Tag Number",
      angler: "Angler",
      captain: "Captain",
      ocean: "Ocean",
      species: "Species",
      location: "Location Description",
      date: "Date Entered",
      created: "Captured Date",
      edit: "",
      share: "Share on",
      picture: "Picture",
      searchTitle:
        "Can't find a record? Use this search tool for quick results! Click here to expand",
    },
    trophyRoom: {
      title: "Trophy Room",
      eventType: "Event Type",
      number: "Number",
      prior: "Prior Number",
      species: "Species",
      ocean: "Ocean",
      locality: "Locality",
      angler: "Angler Name",
      captain: "Captain Name",
      boat: "Boat Name",
      noInfo: "This trophy does not have accurate event information",
      name: "Trophy",
      fisherName: "Fisher Name",
      taggedDate: "Tagged Date",
    },
    competitions: {
      title: "Competition Calculator",
      category: "CATEGORY",
    },
  },
  sidebar: {
    welcome: "Welcome",
    dashboard: "Dashboard",
    tutorials: "Video Tutorials",
    submitTag: "Submit a tag",
    submitRelease: "Submit a release",
    submitRecapture: "Submit a recapture",
    submitJuvenile: "Submit a juvenile",
    website: "TBF Website",
    join: "Join or Renew",
    donate: "Donate",
    shop: "Shop",
    profile: "Profile",
    contactUs: "Contact Us",
    privacy: "Privacy Policy",
    faqs: "FAQs",
    search: "Search",
  },
  footer: {
    findUs: "Find Us",
    rights: "All rights reserved",
  },
  profileForm: {
    becomeMember: "Become a member",
    totalEvents: "Total Events",
    tagsRecaptured: "Tags Recaptured",
    anglerEvents: "Events as angler",
    captainEvents: "Events as captain",
    resetPwd: "Reset Password",
    changePhoto: "Change your profile photo",
    upload: "Upload",
    title: "Title",
    firstName: "First Name",
    lastName: "Last Name",
    suffix: "Suffix Name",
    email: "Email",
    line1: "Address Line 1",
    line2: "Address Line 2",
    city: "City",
    zip: "ZIP",
    state: "State",
    country: "Country",
    shirtSize: "Shirt Size",
    gender: "Gender",
    personalPhone: "Personal Phone",
    workPhone: "Work Phone",
    homePhone: "Home Phone",
    birthday: "Birthday",
    removed: "Picture successfully removed",
    uploaded: "Picture successfully uploaded",
  },
  resetPwd: {
    title: "Reset Password",
    subtitle: "Please enter your new password",
    password: "New Password",
    confirm: "Confirm Password",
    required: "Password is required",
    strengthRule: "Password is not strong enough",
    mismatch: "Password Mismatch",
  },
  form: {
    newNumber: "NEW TAG NUMBER (optional)",
    number: "TAG NUMBER",
    prior: "PRIOR TAG NUMBER",
    ocean: "OCEAN",
    species: "SPECIES",
    quantity: "QUANTITY",
    tagDate: "TAG DATE",
    recaptureDate: "TAG DATE",
    releaseDate: "RELEASE DATE",
    location: "LOCATION NAME",
    value: "VALUE",
    unit: "UNIT",
    determination: "DETERMINATION",
    type: "TYPE",
    hours: "HOURS",
    minutes: "MINUTES",
    degrees: "DEGREES",
    hemisphere: "HEMISPHERE",
    condition: "FISH CONDITION",
    bait: "BAIT TYPE",
    hook: "HOOK",
    hookRemoved: "Hook Removed",
    gear: "GEAR",
    tournament: "TOURNAMENT",
    angler: "ANGLER",
    captain: "CAPTAIN",
    firstMate: "FIRST MATE",
    secondMate: "SECOND MATE",
    boat: "BOAT NAME",
    setMarker: "set marker",
    addUser: "Add New User",
    editUser: "Edit User",
    addFish: "add new",
    linesIn: "LINES IN",
    linesOut: "LINES OUT",
    totalLines: "TOTAL LINES",
    notes: "NOTES",
    notesHint: "Don't forget to add the Release Card Number in this section",
    recaptureHint: "Enter the old tag number",
    typeHint: "Type to search",
    registerHint:
      "If you don't find the requested user, please register him/her",
    maxLimit_300Rule: "Maximum 300 characters",
    maxLimit_100Rule: "Maximum 100 allowed",
    maxLimit_10Rule: "Maximum 10 allowed",
    minLimit_5Rule: "Minimum 5 characters",
    maxLimitRule: "Maximum 10 allowed",
    minLimit: "Negative values not allowed",
    addOne: "You have to add at least one record",
    clickAddNew: "You have to click on ADD NEW to add this record",
    required: "Required",
    timeRule: "0 - 59 values allowed",
    tagRule: "Minimum 6 digits required",
    user: "User",
    owner: "Owner",
    name: "Name",
    model: "Model",
    make: "Make",
    length: "Length",
    port: "Port",
    boatCreated: "Boat successfuly created",
    boatExists: "Boat already exists or something went wrong",
    enteredDate: "ENTERED DATE",
    daysAtLarge: "DAYS AT LARGE",
    fishLength: "FISH LENGTH",
    fishWeight: "FISH WEIGHT",
    fightTime: "FIGHT TIME",
    event: "EVENT",
    firstName: "First Name",
    lastName: "Last Name",
    emailRule: "E-mail must be valid",
    phoneRule: "Phone number must have 10 digits",
    userExists: "User already exists",
    emailUsed: "Email already in use. Please enter a new one",
    personalPhoneUsed:
      "Personal phone number already in use. Please enter a new one",
    sharkEncounter: "Shark Encounter",
    middleName: "Middle Name",
    memberId: "Member ID",
    setMarkerHint: "Click the Set Marker button to confirm map location",
    setLocationHint:
      "Based on your input on previous page, please either choose the closest location in the drop down or input a new location for review and approval",
    warningNote:
      "Please fill out as much information as possible. At minimum, every recapture returned must have a species, date, location/GPS, and at least one member of the fishing team listed. If any of this information is missing, the report is not usable for research purposes",
    noRepeatPriorTag: "number already used as prior tag",
    noSeawaterSurface: "Invalid coords, no seawater surface",
    invalid: "Form validation failed",
    invalidCoords: "Invalid coordinates",
    invalidDate: "Invalid date",
    coordinates: "Coordinates",
    recordExists:
      "Record already exists in the system, please verify and update if needed",
  },
  recordFish: {
    warning:
      "Did you enter the red six digit number of the release card in the notes section?",
    needFulfill: "Some fields in the form need to be fulfilled",
    tagExists:
      "The tag number you´re trying to enter already exists. Please, change it",
    releaseExists: "The record you're trying to enter already exists",
    addedForReview: "Record added for review",
  },
  newLocation: {
    title: "New Location",
    location: "LOCATION NAME",
    created: "Location successfuly created",
    failed: "Location already exists or something went wrong",
  },
  batchReview: {
    clear: "Clear board",
    askAction: "Are you sure to take this action?",
    addRecord: "ADD RECORD",
    clickOn: "Click on",
    toAdd: "button to add records to the board",
    added: "Record added for review",
    addMore: "Add more",
    addRecords: "Add Records",
    editRecords: "Edit Records",
  },
  search: {
    title: "Search Page",
    searchFor: "SEARCH FOR",
    species: "SPECIES",
    ocean: "OCEAN",
    number: "TAG NUMBER",
    priorNumber: "PRIOR TAG NUMBER",
    angler: "ANGLER",
    captain: "CAPTAIN",
    firstMate: "FIRST MATE",
    secondMate: "SECOND MATE",
    user: "USER",
    boat: "BOAT",
    catchStartDate: "CATCH START DATE",
    catchEndDate: "CATCH END DATE",
    location: "LOCATION",
    hook: "HOOK",
    enteredBy: "ENTERED BY",
    enteredByStartDate: "ENTERED START DATE",
    enteredByEndDate: "ENTERED START DATE",
    searchBtn: "Search",
    tags: "TAGS",
    releases: "RELEASES",
    recaptures: "RECAPTURES",
    all: "ALL",
    foundText: "We found",
    records: "records",
    resetBtn: "reset",
    back: "Back",
    actions: "",
    tagDate: "TAG/RELEASE DATE",
    enteredDate: "ENTERED DATE",
    tableTitle: "Search Results",
    preview: "Preview",
  },
  recaptureMail: {
    subject: "New Recapture",
    body: `
    Tag number: \n
    Ocean: \n
    Species: \n
    Recapture Date: \n
    Location description (coordinates if available): \n
    \n
    Fish's length: \n
    Length determination (measured or estimated): \n
    Fish's weight: \n
    Weight determination (measured or estimated): \n
    Fight hours: \n
    Fight minutes: \n
    \n
    Fish's condition upon release (Excellent, Good, Fair, Dead): \n
    Bait type (Live, Dead or Artificial): \n
    Gear type: \n
    Hook type (circle or J hook): \n
    \n
    If you retagged fish, New Tag Number: \n     
    \n
    Boat Name: \n
    Shark Encounter (y/n): \n
  
    - Angler - \n
    Name: \n
    Shirt size: \n
    Address: \n
    Email: \n
  
    - Captain - \n
    Name: \n
    Shirt size: \n
    Address: \n
    Email: \n
  
    - First Mate - \n
    Name: \n
    Shirt size: \n
    Address: \n
    Email: \n
  
    - Second Mate - \n
    Name: \n
    Shirt size: \n
    Address: \n
    Email: \n
    `,
  },
  recaptcha: {
    failedValidation:
      "Something went wrong on credentials validation, try again",
    failedService:
      "Captcha validation service has failed, please contact the Billfish's development team.",
    errorCodesIntro: "The following error codes has been issued",
    failed3Times:
      "You have failed captcha validation 3 times in a row, we cannot identify you as human. You need to reload the site in order to continue login process",
  },
  rewardSolicitation: {
    title: "TBF Reward",
    tbfMsg:
      "We at TBF cannot thank you enough for reporting this recapture to us! Referred to \"closing the scientific loop\", recaptures are the most valuable data we receive. As a thank you, we'd love to send you a TBF t-shirt as a reward! If you're interested, please provide a valid address above and we'd be happy to send one your way.",
    shirtSizeQuestion: "What size would you like your t-shirt?",
    shirtSize: "Shirt Size",
    rewardNotification:
      "If you choose not to receive any reward including the certificate and report, please indicate here:",
    userNotification:
      "Please note that you can modify these options at any time in the Profile section of your account",
    wantIt: "I would like to receive a t-shirt and certificate/report",
    receiveCertificate:
      "I would like to receive only a certificate (no t-shirt)",
    receiveReward: "I would like to receive only a t-shirt (no certificate)",
    doNotWantIt: "I would not like a t-shirt or certificate",
    address: "Address?",
    confirmationTitle: "Are you sure?",
    confirmationSubtitle: "You want to remove it?",
    confirmationOk: "Yes, remove it",
    confirmationCancel: "No, keep it",
    singleLocationTitle: "Send all the rewards to a single location?",
    singleLocationTooltip:
      "We understand that you might want to simplify the process of receiving your rewards. To make it easier, we offer the option to ship all the rewards to a single location. Please note that by choosing this option, you are confirming that the provided address will receive rewards for all the recipients. Ensure the address is accurate and accessible to avoid any delays or lost shipments.",
    singleLocationLabel: "Address?",
    recipientCountWarning:
      "It's required to add/activate one or more recipients to submit the rewards. If you don't want to receive any reward, please dismiss this form by clicking the 'X' in the top left corner.",
    recipientSubmit: "Click here to submit",
    recipientAction: "Click here to take action",
    recipientSubmitted: "Submitted",
    recipientDiscarded: "Discarded",
    recipientAddName: "Add recipient name",
    recipientHint:
      "Hit the checkbox to activate the recipient. Once activated, you can add the reward details and submit the form.",
    recipientAddressTooltip:
      "Ensure the address is accurate and accessible to avoid any delays or lost shipments.",
    addRecipient: "Add new recipient",
    addRecipientWarning: "Please complete the current recipient",
    submitFailed: "Failed to submit rewards",
    socialMediaQuestion:
      "If we end up posting this recapture on our social media platforms, is there anyone you would like us to tag? Please provide any handles below, separated by a comma",
  },
  batchFeature: {
    applyHint:
      "Use this feature to select which params will be used from this record to be populated throughout all records. After record validation all selected params will be applied to all records.",
    apply: "Apply in batch",
    applyLocation: "Populate coordinates, location description and ocean",
    applyBoat: "Populate boat name",
    applyAngler: "Populate angler user",
    applyCaptain: "Populate captain user",
    applyFirstMate: "Populate first mate user",
    applySecondMate: "Populate second mate user",
    applyTournament: "Populate tournament event",
    applyHook: "Populate hook type",
    applyBait: "Populate bait type",
    applyGear: "Populate gear type",
    applySpecies: "Populate species",
    uploadHint:
      "Upload the file by dragging and dropping into the dashed area or click the below button",
    upload: "Upload file",
    rememberDownload: "Remember to download the latest",
    template: "Template File",
    recommended:
      "Microsoft Excel is recommended for the best experience in viewing and editing the file",
    title: "Batch Import",
    total: "Total",
    valid: "Valid",
    invalid: "Invalid",
    clickToEdit: "Click here to edit",
    unsupportedFile: "Unsupported file type, only Excel files allowed",
    unableToReadFile: {
      title: "An error occurred parsing your submission",
      message: "Please download & use the latest template \n {error}",
    },
    unableToBuildTemplate: "Unable to build the tag batch template",
    noRecordsFound: "No records found",
    invalidRecord: "Record is invalid, check the fields",
    invalidRecords: "Some records are invalid, please check it",
    duplicatedTagNumber: "Tag number already exists",
    recordsRegisteredTitle: "All done!",
    recordsRegisteredSubtitle: "All records have been registered successfully",
    recordsNotRegisteredTitle: "Unable to save",
    recordsNotRegisteredSubtitle: "Records weren't registered",
    clearDataSubtitle:
      "Are you sure to proceed, this will clear your submission",
    clearDataTitle: "Clear all data",
    proceed: "Proceed",
  },
  confirmation: "Confirmation",
  email: "Email",
  password: "Password",
  privacy: "Privacy",
  close: "Close",
  cancel: "Cancel",
  save: "Save",
  saving: "Saving",
  submit: "Submit",
  validate: "Validate",
  tags: "Tags",
  releases: "Releases",
  recaptures: "Recaptures",
  juveniles: "Juvenile Billfish",
  noRecords: "No records found",
  noAddress: "No address registered",
  new: "New",
  edit: "Edit",
  failed: "Something went wrong",
  notSpecified: "Not Specified",
  loading: "Loading",
  registered: "Registered",
  updated: "Updated",
  created: "Created",
  clear: "Clear",
  finish: "Finish",
  tutorials: "Video Tutorials",
  profile: "Profile",
  logout: "Logout",
  back: "Back",
  saveNew: "Save & New",
  saveCopy: "Save & Copy",
  update: "Update",
  apply: "Apply",
  load: "Load",
  replace: "Replace",
  loadImage: "Load Picture",
  draft: "Draft",
  bindWarning: {
    text: "Your profile has been bonded to your TBF membership #{member}, please update your info {url}. If you need help, {mail}!",
    link: "here",
    contact: "contact us",
  },
  next: "Next",
  help: "Help",
  date: "DATE",
  unknown: "Unknown",
  yes: "Yes",
  no: "No",
};
export default en;
