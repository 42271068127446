<template>
  <v-autocomplete
    :id="'autocomplete-' + tagLabel"
    :items="docs"
    :label="label"
    :loading="loading"
    v-model="item"
    item-value="_id"
    hide-details
    return-object
    :search-input.sync="searchInput"
    :rules="rules"
    no-filter
    :prepend-icon="edit ? 'mdi-account-edit' : null"
    @click:prepend="edit ? $emit('edit') : null"
    outlined
    :dense="full"
    clearable
    attach
    @change="$emit('change')"
    class="user-field"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title class="name-title">
          {{
            [item.firstName, item.lastName].filter(Boolean).join(" ") ||
            "No name registered"
          }}
          <v-chip
            v-if="isCurrentProfile(item)"
            color="primary"
            label
            small
            outlined
          >
            Current profile
          </v-chip>
        </v-list-item-title>
        <v-list-item-subtitle v-if="item.email">
          {{ item.email }}
        </v-list-item-subtitle>
        <v-list-item-subtitle> {{ getAddress(item) }} </v-list-item-subtitle>
        <v-list-item-subtitle v-if="item.memberId">
          Member ID: {{ item.memberId }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:selection="{ item }">
      {{
        [item.firstName, item.lastName].filter(Boolean).join(" ") || item.email
      }}
    </template>
    <template v-slot:no-data>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("form.typeHint") }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="addNew">
            {{ $t("form.registerHint") }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="addNew">
          <v-btn icon @click="$emit('add')" :x-large="!full" :outlined="!full">
            <v-icon color="primary">mdi-account-edit</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions } from "vuex";
import { getUserInfo } from "../../handlers";
export default {
  name: "autocomplete-users",
  props: {
    label: {
      type: String,
      default: () => "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Object, String],
      default: () => null,
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
    addNew: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    docs: [],
    search: null,
    loading: false,
    tempName: null,
    userId: null,
  }),
  mounted() {
    const user = getUserInfo();
    const { _id, id } = user || {};
    this.userId = _id || id;
  },
  computed: {
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    edit() {
      return this.editable && !!(this.value?._id || this.value?.id);
    },
    searchAction() {
      return this.searchable && !!(this.value?._id || this.value?.id);
    },
    searchInput: {
      get: function () {
        return this.search;
      },
      set: function (value) {
        if (value !== this.search) {
          this.search = value;
          value ? this.getUserList(value) : (this.docs = []);
        }
      },
    },
    item: {
      get: function () {
        return this.getItemValue();
      },
      set: function (item) {
        this.$emit("input", item);
      },
    },
    tagLabel() {
      return this.label.toLowerCase().replace(" ", "") || "user";
    },
  },
  methods: {
    ...mapActions("users", ["getUsers"]),
    getItemValue() {
      const { firstName, lastName, _id, id } = this.value || {};
      const name = [firstName, lastName].filter(Boolean).join(" ") || "";
      const userId = _id || id;

      if (userId?.includes("@")) {
        this.docs = [{ _id: userId, email: userId }];
        return userId;
      }

      if (!name) {
        return userId;
      }

      if (this.tempName === name && this.docs.length !== 1) {
        return userId;
      }

      if (userId) {
        const exist = this.docs.some((doc) => userId === doc._id);
        !exist && this.getUserList(name);
        return userId;
      }

      if (!this.docs.length) {
        this.getUserList(name);
        return null;
      }

      if (this.docs.length === 1) {
        const { _id, id } = this.docs[0];
        return _id || id;
      }

      return null;
    },
    set: function (item) {
      this.$emit("input", item);
    },
    async getUserList(name = "") {
      this.tempName = name;
      this.loading = true;
      const payload = { name, page: 1, limit: 10 };
      const response = await this.getUsers(payload);
      this.docs = response?.data?.docs || [];
      this.loading = false;
    },
    isCurrentProfile(user) {
      const { _id, id } = user || {};
      return _id === this.userId || id === this.userId;
    },
    getAddress(user) {
      const { city, country } = user?.address || {};
      return (
        [city, country].filter(Boolean).join(", ") || "No address registered"
      );
    },
  },
};
</script>

<style>
.user-field div[class~="v-input__append-outer"] {
  display: contents;
}

.name-title {
  display: flex;
  justify-content: space-between;
}
</style>
