<template>
  <v-card fluid>
    <v-toolbar flat dark color="primary" max-height="100px">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ $t("new") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="submit">
          {{ $t("save") }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-form ref="tournamentForm" class="mt-5">
        <v-row>
          <v-col cols="12">
            <v-text-field
              :label="$t('form.name')"
              :rules="rule"
              v-model="input.name"
              hide-details
              outlined
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { TournamentsViewModel } from "../models";
import { notifyMessage } from "../../handlers/notifications";
export default {
  name: "new-tournament",
  data: () => ({
    input: new TournamentsViewModel(),
  }),
  computed: {
    rule() {
      return [(v) => !!v || this.$t("form.required")];
    },
  },
  methods: {
    ...mapActions("entry", ["addItem"]),
    async submit() {
      const valid =
        this.$refs.tournamentForm && this.$refs.tournamentForm.validate();
      if (valid) {
        const { name } = this.input;
        const response = await this.addItem({
          item: "tournaments",
          data: { name, approved: false },
        });
        const { success } = response?.data || {};
        notifyMessage(
          success
            ? this.$t("form.tournamentCreated")
            : this.$t("form.tournamentExists")
        );
        this.$emit("close");
      }
    },
    init() {
      this.$refs.tournamentForm && this.$refs.tournamentForm.reset();
    },
  },
};
</script>
