<template>
  <v-dialog
    v-model="onRecord"
    max-width="1200px"
    scrollable
    :fullscreen="full"
    persistent
  >
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="showWarning" persistent max-width="290">
      <v-card>
        <v-card-title />
        <v-card-text>
          {{ $t("recordFish.warning") }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="focusNotes"> No </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showConfirmation">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card title>
      <v-snackbar v-model="notify" :timeout="3000" top>
        {{ message }}
      </v-snackbar>
      <v-toolbar flat dark color="primary" max-height="100px">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> {{ title }} </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text ref="contentCardBatch">
        <release-form
          ref="releaseForm"
          setUser
          v-if="isRelease"
          :step="coordsStep"
        />
        <tag-form
          ref="tagForm"
          :isRecapture="isRecapture"
          setUser
          :step="coordsStep"
          v-else
        />
      </v-card-text>
      <v-toolbar flat dark color="primary">
        <v-btn dark @click="discardDraft" v-if="data.draft" class="mr-2">
          Discard Draft
        </v-btn>
        <v-btn
          @click="coordsStep = true"
          color="white"
          class="primary--text"
          style="min-width: 164px"
          v-if="!coordsStep"
        >
          {{ $t("back") }}
        </v-btn>
        <v-spacer />
        <v-btn
          @click="nextStep"
          color="white"
          class="primary--text"
          style="min-width: 164px"
          v-if="coordsStep"
        >
          {{ $t("next") }}
        </v-btn>
        <confirmation
          ref="confirmation"
          :data="data"
          :event="type"
          :isNew="newRecord"
          @check="checkForm"
          @save="saveRecord"
          v-else
        />
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { cloneObject, getUserInfo } from "../../handlers";
import {
  CoordinatesViewModel,
  CaptureViewModel,
  CrewViewModel,
} from "../models";
import Confirmation from "./Confirmation.vue";
import ReleaseForm from "./ReleaseForm.vue";
import TagForm from "./TagForm.vue";
import {
  notifyConfirmation,
  notifyMessage,
} from "../../handlers/notifications";
export default {
  components: {
    Confirmation,
    ReleaseForm,
    TagForm,
  },
  name: "record-fish",
  data: () => ({
    type: "tags",
    newRecord: false,
    docs: null,
    user: null,
    data: cloneObject(initInput),
    open: false,
    notify: false,
    message: null,
    onRecord: false,
    overlay: false,
    eventType: null,
    showWarning: false,
    fishPicture: null,
    coordsStep: true,
    recordId: null,
  }),
  computed: {
    title() {
      if (this.eventType) {
        switch (this.eventType) {
          case "R":
            return this.getUpperCaseSingular(this.$t("releases"));

          case "C":
            return this.getUpperCaseSingular(this.$t("recaptures"));

          case "T":
          default:
            return this.getUpperCaseSingular(this.$t("tags"));
        }
      }

      return this.getUpperCaseSingular(this.$t(this.type));
    },
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isRelease() {
      return this.title === this.getUpperCaseSingular(this.$t("releases"));
    },
    isRecapture() {
      return this.title === this.getUpperCaseSingular(this.$t("recaptures"));
    },
  },
  methods: {
    getUpperCaseSingular(str = "") {
      if (!str) return "";
      return str.toLowerCase() === "devoluciones"
        ? "DEVOLUCIÓN"
        : str.substring(0, str.length - 1).toUpperCase();
    },
    init(record, type) {
      type && (this.type = String(type).toLowerCase());
      this.onRecord = true;
      this.coordsStep = true;
      this.newRecord = !record;
      this.data.draft = !!record?.draft;
      const { id, _id } = record || {};
      this.recordId = id || _id;
      if (!this.newRecord) {
        this.eventType = record?.isTagOrRelease;
        this.havePriorTag = record?.priorTag?.priorTagNumber;
      }
      setTimeout(async () => {
        this.$refs.contentCardBatch &&
          (this.$refs.contentCardBatch.scrollTop = 0);
        if (this.isRelease) {
          this.$refs.releaseForm &&
            (await this.$refs.releaseForm.initRecord(record));
        } else {
          this.$refs.tagForm && (await this.$refs.tagForm.initRecord(record));
        }
      }, 50);
    },
    async checkForAbandonedRecord() {
      if (!this.newRecord && !this.data?.draft) {
        return;
      }

      const { data } = this.getFormData() || {};
      const input = cloneObject(data);
      input.angler = new CrewViewModel().angler;
      delete input.boatName;
      delete input.enteredBy;
      delete input.updatedBy;
      const uncompletedForm =
        JSON.stringify(input) !== JSON.stringify(initInput);

      if (!uncompletedForm) {
        return;
      }

      this.overlay = true;
      await this.deleteRecord(this.title, data?._id);

      data.draft = true;
      const response = await this.addEditRecord(this.title, data);
      const { success } = response?.data || {};
      if (!success) {
        this.message = this.$t("failed");
        this.notify = true;
      }
      this.overlay = false;
    },
    async close() {
      if (!this.coordsStep) {
        await this.checkForAbandonedRecord();
      }
      this.$refs.fishingLocations && this.$refs.fishingLocations.close();
      this.onRecord = false;
    },
    getFormData() {
      const { releaseForm, tagForm } = this.$refs;
      const input = this.isRelease ? releaseForm.submit() : tagForm.submit();
      input?.picture && (this.fishPicture = input.picture);
      const user = getUserInfo();
      const payload = {
        ...cloneObject(initInput),
        ...cloneObject(input?.data),
        updatedBy: user?.email,
      };

      if (this.newRecord) {
        payload.enteredBy = user?.email;
      } else if (Object.keys(payload).some((key) => key === "enteredBy")) {
        delete payload.enteredBy;
      }

      return { data: payload, valid: input?.valid };
    },
    async checkForm() {
      const { data: payload, valid } = this.getFormData() || {};
      let emptyNote = false;
      this.isRelease && (emptyNote = !payload?.capture?.notes);

      if (valid) {
        this.showWarning = emptyNote;
        this.data = { ...payload };
        !this.showWarning && this.data && (await this.check(this.data));
      } else {
        this.$refs.confirmation && (this.$refs.confirmation.open = false);
        this.notify = true;
        this.message = this.$t("recordFish.needFulfill");
      }
    },
    async deleteRecord(title, id) {
      switch (title) {
        case this.getUpperCaseSingular(this.$t("tags")):
          await this.deleteTag({ id });
          break;

        case this.getUpperCaseSingular(this.$t("releases")):
          await this.deleteRelease({ id });
          break;

        case this.getUpperCaseSingular(this.$t("recaptures")):
          await this.deleteRecapture({ id });
          break;

        default:
          break;
      }
      this.$emit("done");
    },
    async discardDraft() {
      this.overlay = true;
      await this.deleteRecord(this.title, this.recordId);
      this.$refs.fishingLocations && this.$refs.fishingLocations.close();
      this.onRecord = false;
      this.overlay = false;
    },
    async addEditRecord(title, data, newRecord = true) {
      let response = null;
      switch (title) {
        case this.getUpperCaseSingular(this.$t("tags")):
          response = await (newRecord ? this.addTag(data) : this.editTag(data));
          break;

        case this.getUpperCaseSingular(this.$t("releases")):
          response = await (newRecord
            ? this.addRelease(data)
            : this.editRelease(data));
          break;

        case this.getUpperCaseSingular(this.$t("recaptures")):
          response = await (newRecord
            ? this.addRecapture(data)
            : this.editRecapture(data));
          break;

        default:
          break;
      }
      return response;
    },
    async saveRecord(type) {
      this.overlay = true;
      let open = false;
      this.data.draft = false;
      const response = await this.addEditRecord(
        this.title,
        this.data,
        this.newRecord
      );

      this.notify = true;
      const { success, response: record, message } = response?.data || {};
      if (success) {
        const { angler, captain, firstMate, secondMate, id, _id } =
          record || {};
        const crew = [angler, captain, firstMate, secondMate]
          .map((user) => user?.id)
          .filter(Boolean);
        this.$emit("done", {
          recordType: this.title,
          recordId: id || _id,
          crew,
        });
        this.message = this.newRecord
          ? this.$t("registered")
          : this.$t("updated");
        this.fishPicture &&
          (await this.uploadPicture(
            this.newRecord ? record?.id : this.data?.id
          ));
        if (type) {
          open = true;
          if (type === "new") {
            this.coordsStep = true;
          }
          if (this.isRelease) {
            type === "new"
              ? this.$refs.releaseForm.initRecord()
              : this.$refs.releaseForm.resetInfo();
          } else {
            type === "new"
              ? this.$refs.tagForm.initRecord()
              : this.$refs.tagForm.resetInfo();
          }
        }
        open &&
          this.$refs.contentCardBatch &&
          (this.$refs.contentCardBatch.scrollTop = 0);
        this.onRecord = open;
      } else {
        this.message = message || this.$t("failed");
      }

      if (this.$refs.confirmation) {
        this.$refs.confirmation.open = false;
      }

      this.overlay = false;
    },
    async check(data) {
      let check = { event: this.type, payload: data };
      this.overlay = true;
      const response = await this.checkEvent(check);
      this.overlay = false;

      if (!response?.data?.docs) {
        if (this.$refs.confirmation) {
          this.$refs.confirmation.open = true;
        }
        return;
      }

      if (!this.isRelease) {
        this.notify = true;

        if (this.isRecapture) {
          this.message = this.$t("recordFish.recaptureExists");
          return;
        }

        this.message = this.$t("recordFish.tagExists");
        return;
      }

      notifyConfirmation(
        this.$t("recordFish.releaseExistsConfirmationTitle"),
        this.$t("warning"),
        () => {},
        () => {
          if (this.$refs.confirmation) {
            this.data.duplicate = true;
            this.$refs.confirmation.open = true;
          }
          notifyMessage(this.$t("recordFish.releaseDuplicatedMessage"));
        },
        this.$t("recordFish.releaseExistsConfirmationNo"),
        this.$t("recordFish.releaseExistsConfirmationYes")
      );
    },
    focusNotes() {
      this.showWarning = false;
      this.$refs.releaseForm && this.$refs.releaseForm.focusNotes();
    },
    async showConfirmation() {
      this.showWarning = false;
      this.data && (await this.check(this.data));
    },
    async uploadPicture(id) {
      if (!id) return;
      const response = await this.uploadFishPicture({
        id,
        image: this.fishPicture,
      });
      this.fishPicture = null;
    },
    nextStep() {
      if (this.isRelease) {
        const valid =
          this.$refs.releaseForm &&
          this.$refs.releaseForm.validateCoordinates();

        if (!valid) {
          this.notify = true;
          this.message = this.$t("recordFish.needFulfill");
          return;
        }
      } else {
        const valid =
          this.$refs.tagForm && this.$refs.tagForm.validateCoordinates();

        if (!valid) {
          this.notify = true;
          this.message = this.$t("recordFish.needFulfill");
          return;
        }
      }

      this.coordsStep = false;
    },
    ...mapActions("entry", [
      "addTag",
      "editTag",
      "deleteTag",
      "addRelease",
      "editRelease",
      "deleteRelease",
      "addRecapture",
      "editRecapture",
      "deleteRecapture",
      "checkEvent",
      "uploadFishPicture",
    ]),
  },
};

const initInput = {
  ...new CrewViewModel(),
  ...new CaptureViewModel(),
  ...new CoordinatesViewModel(),
};
</script>
