<template>
  <v-card fluid>
    <v-toolbar flat dark color="primary" max-height="100px">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title> {{ $t("newLocation.title") }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="submit"> {{ $t("save") }} </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-form ref="locationForm" class="mt-5">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('newLocation.location')"
              :rules="rule"
              v-model="input.name"
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12" sm="6">
            <auto-complete
              :label="$t('form.ocean')"
              :rules="rule"
              itemType="oceans"
              v-model="input.ocean"
            />
          </v-col>
        </v-row>

        <coordinates-form v-model="input.coordinates" ref="coordsForm" />

        <fishing-locations
          ref="fishingLocations"
          @coords="setCoords"
          draggable
          class="mt-5"
          :input="input"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { CoordinatesViewModel } from "../models";
import AutoComplete from "@/components/appComponents/AutoComplete.vue";
import CoordinatesForm from "./CoordinatesForm.vue";
import FishingLocations from "@/views/Dashboard/FishingLocations.vue";
import { notifyMessage } from "../../handlers/notifications";
export default {
  components: { AutoComplete, CoordinatesForm, FishingLocations },
  name: "new-location",
  props: {
    onlyForm: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: window.structuredClone(INPUT),
    },
  },
  data: () => ({
    input: window.structuredClone(INPUT),
    rule: [(v) => !!v || "Required"],
  }),
  mounted() {
    if (!this.input["name"]) {
      this.input = window.structuredClone(INPUT);
    }
    this.$refs.locationForm && this.$refs.locationForm.reset();
  },
  watch: {
    input: {
      handler: "submitOnChange",
      deep: true,
    },
    value: {
      handler: "initForm",
    },
  },
  methods: {
    ...mapActions("entry", ["saveLocation"]),
    validateForm() {
      let validCoords = false;
      if (this.$refs.coordsForm) {
        validCoords = this.$refs.coordsForm.validate();
      }

      let valid = false;
      if (this.$refs.locationForm) {
        valid = this.$refs.locationForm.validate();
      }

      return valid && validCoords;
    },
    async submit() {
      const valid = this.validateForm();
      if (valid) {
        this.input["approved"] = false;
        const response = await this.saveLocation(this.input);
        const { data } = response || {};
        const { success } = data || {};

        if (!success) {
          notifyMessage(this.$t("newLocation.failed"));
          this.$emit("close");
          return;
        }

        notifyMessage(this.$t("newLocation.created"));
        this.$emit("location", this.input);
        this.$emit("close");
      }
    },
    setCoords(coordinates) {
      if (!coordinates) {
        this.input.coordinates = new CoordinatesViewModel().coordinates;
        return;
      }
      this.input.coordinates = { ...coordinates };
    },
    submitOnChange(input) {
      const { ocean } = input;
      this.$emit("input", { ...input, ocean: { name: ocean } });
    },
    initForm(input) {
      const { ocean, coordinates: inputCoordinates } = input;
      const initial = { ...input, ocean: ocean?.name || ocean };

      const diffLocation =
        JSON.stringify(inputCoordinates) !==
        JSON.stringify(this.input.coordinates);
      const initialName = input.name || input.coordinates?.description;
      const inputName = this.input.name || this.input.coordinates?.description;

      if (diffLocation || initialName !== inputName) {
        this.input = window.structuredClone(initial);
      }
    },
  },
};

const INPUT = {
  name: null,
  ocean: null,
  coordinates: new CoordinatesViewModel().coordinates,
};
</script>
