const es = {
  login: {
    signIn: "Iniciar sesión",
    rememberMe: "¿Recordarme?",
    forgotPwd: "¿Olvidaste la contraseña?",
    failedPwd3Times: "Has fallado 3 veces, por favor restablece tu contraseña",
    close: "Cerrar",
    haveAccount: "¿No tienes cuenta?",
    reload: "Recargar",
    resetPwd: "Restablecer contraseña",
    activate: "Activar cuenta",
    activateMsg:
      "Por favor, active su cuenta para confirmar su correo electrónico",
  },
  signUp: {
    title: "Registrarse",
    haveAccount: "¿Tienes cuenta?",
    confirm: "Confirmar Contraseña",
    firstName: "Nombre",
    lastName: "Apellido",
  },
  forgotPwd: {
    title: "Recuperar contraseña",
    subtitle:
      "Por favor, entre un correo electrónico válido para recuperar su contraseña",
    okButton: "Enviar correo de recuperación",
    cancelButton: "Regresar",
  },
  activationEmail: {
    title: "Correo de Activación",
    subtitle:
      "Por favor, entre el correo electrónico asociado a su cuenta para ser confirmada",
    okButton: "Enviar correo de activación",
    cancelButton: "Regresar",
  },
  statistics: {
    title: "Estadísticas",
    tags: "TOTAL DE ETIQUETAS",
    releases: "TOTAL DE DEVOLUCIONES",
    recaptures: "TOTAL DE RECAPTURAS",
    tagsLastYear: "ETIQUETAS DEL ÚLTIMO AÑO",
    releasesLastYear: "DEVOLUCIONES DEL ÚLTIMO AÑO",
    recapturesLastYear: "RECAPTURAS DEL ÚLTIMO AÑO",
    users: "TOTAL DE USUARIOS",
  },
  dashboard: {
    roleConfirm: {
      title: "¿Cuál es tu rol?",
      role: "Rol",
    },
    forOthersConfirm: {
      title: "¿Estás añadiendo registros para ti o para otros?",
      own: "Para mí",
      others: "Otros",
    },
    batchUploadConfirm: {
      title: "Entrar {type}",
      subtitle:
        "Deseas entrar un registro o prefieres usar un archivo Excel para importar varios",
      single: "Entrar un registro",
      multiple: "Entrar varias a la vez",
    },
    welcomeMsg: {
      p1: `
      The Billfish Foundation's Tag and Release Program es la base de datos privada de marcado de marlines más grande del mundo, que ayuda a proporcionar datos vitales de marlines asociados con la migración, la edad, el crecimiento, etc. y actúa como la piedra angular del trabajo de TBF
        `,
      p2: `
      Reconocemos que TBF no sería el pilar que es hoy sin la comunidad de pesca deportiva. Sin el apoyo de miles de pescadores, capitanes y compañeros que se esfuerzan por lograr un objetivo de conservación, TBF no tendría una base sobre la cual apoyarse mientras impulsamos varias políticas de gestión de marlines. Cada pez que es marcado, liberado, recapturado y reportado nos brinda una pieza más en el creciente rompecabezas de marlines.
        `,
      thanks: "Te agradecemos por tu apoyo.",
      team: "-Equipo TBF",
      dontShowAgain: "No mostrar este mensaje nuevamente",
    },
    fishingLocations: {
      title: "Lugares de Pesca",
      disableNavigation: "Deshabilitar Navegación",
      enableNavigation: "Habilitar Navegación",
      setCurrent: "Posición actual",
      geolocationNotSupported:
        "Geolocalización no es soportada por este dispositivo",
      allowGeolocation: "Permitir geolocalización",
      selectLocation: "Haga clic para seleccionar ubicación",
      clearMarker: "Borrar marcador",
      help: {
        title: "Cómo funciona ...",
        setMarker: "Asignar marcador",
        setMarkerDescription:
          'Para fijar una ubicación en el mapa o establecer un marcador, debe hacer clic en el área del mapa y se establecerá un marcador. Si desea fijar las coordenadas de la ubicación seleccionada, solo necesita hacer clic en la ventana emergente "Haga clic para seleccionar ubicación".',
        setCurrent: "Asignar tu actual localización",
        setCurrentDescription:
          'Para establecer su ubicación actual, puede hacer clic en el botón "Posición actual" en la parte superior derecha del área del mapa.',
        modifyLocation: "Modificar la localización",
        modifyLocationDescription:
          'Para modificar las coordenadas o la ubicación fijada, puede hacer clic en el botón "Habilitar navegación" en la parte inferior izquierda del área del mapa. Eso le permitirá desplazarse o hacer zoom y moverse por el mapa, y encontrar la ubicación deseada.',
        removeLocation: "Remover la localización seleccionada",
        removeLocationDescription:
          'Para eliminar el pin/marcador, puede hacer clic en el botón "Borrar marcador" en la parte superior derecha del área del mapa.',
      },
    },
    fishingHistory: {
      title: "Historial de Pesca",
      own: "Propio",
      others: "Otros",
      forOthers: "Entrado para otros",
      number: "Número de etiqueta",
      angler: "Pescador",
      captain: "Capitan",
      ocean: "Océano",
      species: "Especies",
      location: "Descripción del lugar",
      date: "Fecha entrada",
      created: "Fecha de captura",
      edit: "",
      share: "Compartir en",
      picture: "Imagen",
      searchTitle:
        "¿No encuentras un registro? ¡Usa esta herramienta de búsqueda para obtener resultados rápidos! Haz clic aquí para expandir",
    },
    trophyRoom: {
      title: "Sala de Trofeos",
      eventType: "Tipo de evento",
      number: "Número",
      prior: "Número anterior",
      species: "Especies",
      ocean: "Océano",
      locality: "Localidad",
      angler: "Nombre del pescador",
      captain: "Nombre del capitán",
      boat: "Nombre del bote",
      noInfo: "Este trofeo no tiene información precisa del evento",
      name: "Trofeo",
      fisherName: "Nombre del pescador",
      taggedDate: "Fecha de etiquetado",
    },
    competitions: {
      title: "Calculadora de Competiciones",
      category: "CATEGORÍA",
    },
  },
  sidebar: {
    welcome: "Bienvenido",
    dashboard: "Tablero",
    tutorials: "Video Tutoriales",
    submitTag: "Entrar una etiqueta",
    submitRelease: "Entrar una devolución",
    submitRecapture: "Entrar una recaptura",
    submitJuvenile: "Entrar un pez joven",
    website: "Sitio Web TBF",
    join: "Únete o Renueva",
    donate: "Donar",
    shop: "Tienda",
    profile: "Perfil",
    contactUs: "Contáctanos",
    privacy: "Política de privacidad",
    faqs: "Preguntas frecuentes",
    search: "Búsqueda",
  },
  footer: {
    findUs: "Encuéntranos",
    rights: "Todos los derechos reservados",
  },
  profileForm: {
    becomeMember: "Conviértete en miembro",
    totalEvents: "Total de eventos",
    tagsRecaptured: "Etiquetas recapturadas",
    anglerEvents: "Eventos como pescador",
    captainEvents: "Eventos como capitán",
    resetPwd: "Cambiar contraseña",
    changePhoto: "Cambia tu foto de perfil",
    upload: "Subir",
    title: "Título",
    firstName: "Nombre",
    lastName: "Apellido",
    suffix: "Sufijo",
    email: "Correo electrónico",
    line1: "Dirección 1",
    line2: "Dirección 2",
    city: "Ciudad",
    zip: "ZIP",
    state: "Estado",
    country: "País",
    shirtSize: "Talla",
    gender: "Género",
    phone: "Teléfono",
    personalPhone: "Teléfono Personal",
    workPhone: "Teléfono de Trabajo",
    homePhone: "Teléfono de Casa",
    birthday: "Cumpleaños",
    removed: "Imagen eliminada satisfactoriamente",
    uploaded: "Imagen subida satisfactoriamente",
  },
  resetPwd: {
    title: "Cambiar contraseña",
    subtitle: "Por favor, entre su nueva contraseña",
    password: "Nueva contraseña",
    confirm: "Confirmar contraseña",
    required: "Contraseña es requerida",
    strengthRule: "La contraseña aún no es lo suficientemente segura",
    mismatch: "No coinciden las contraseñas",
  },
  form: {
    newNumber: "NUEVO NÚMERO DE ETIQUETA (opcional)",
    number: "NÚMERO DE ETIQUETA",
    prior: "ETIQUETA ANTERIOR",
    ocean: "OCÉANO",
    species: "ESPECIES",
    quantity: "CANTIDAD",
    tagDate: "FECHA DE ETIQUETADO",
    recaptureDate: "FECHA DE RECAPTURA",
    releaseDate: "FECHA DE LIBERACIÓN",
    location: "NOMBRE DEL LUGAR",
    value: "VALOR",
    unit: "UNIDAD",
    determination: "DETERMINACIÓN",
    type: "TIPO",
    hours: "HORAS",
    minutes: "MINUTOS",
    degrees: "GRADOS",
    hemisphere: "HEMISFERIO",
    condition: "CONDICIÓN DEL PEZ",
    bait: "TIPO DE CARNADA",
    hook: "ANZUELO",
    hookRemoved: "Anzuelo removido",
    gear: "EQUIPO",
    tournament: "TORNEO",
    angler: "PESCADOR",
    captain: "CAPITÁN",
    firstMate: "PRIMER OFICIAL",
    secondMate: "SEGUNDO OFICIAL",
    boat: "NOMBRE DEL BOTE",
    setMarker: "ubicar marcador",
    addUser: "Agregar Nuevo Usuario",
    editUser: "Editar Usuario",
    addFish: "añadir",
    linesIn: "LINES IN",
    linesOut: "LINES OUT",
    totalLines: "TOTAL DE LÍNEAS",
    notes: "NOTAS",
    notesHint:
      "Recuerda añadir el número de liberación de la tarjeta en esta sección",
    recaptureHint: "Ingrese el número de etiqueta anterior",
    typeHint: "Escriba para buscar",
    registerHint: "Si no encuentra el usuario solicitado, por favor regístrelo",
    maxLimit_300Rule: "Máximo 300 caracteres",
    maxLimit_100Rule: "Máximo 100 permitidos",
    maxLimit_10Rule: "Máximo 10 permitidos",
    minLimit_5Rule: "Mínimo 5 permitidos",
    maxLimitRule: "Máximo 10 permitidos",
    minLimit: "Los valores negativos no son permitidos",
    addOne: "Debes agregar al menos un registro",
    clickAddNew: "Debes hacer click en AÑADIR par agregar este registro",
    required: "Requerido",
    timeRule: "Valores permitidos entre 0 - 59",
    tagRule: "Requeridos 6 dígitos como mínimo",
    user: "Usuario",
    owner: "Propietario",
    name: "Nombre",
    boatName: "Nombre del Bote",
    tournamentName: "Nombre del Torneo",
    model: "Modelo",
    make: "Hecho",
    length: "Largo",
    port: "Puerto",
    boatCreated: "Bote creado satisfactoriamente",
    boatExists: "El bote ya existe en el registo o la operación falló",
    tournamentCreated: "Torneo creado satisfactoriamente",
    tournamentExists: "El torneo ya existe en el registro o la operación falló",
    enteredDate: "FECHA DE REGISTRO",
    daysAtLarge: "DÍAS TRANSCURRIDOS",
    fishLength: "LARGO DEL PEZ",
    fishWeight: "PESO DEL PEZ",
    fightTime: "TIEMPO DE PELEA",
    event: "EVENTO",
    firstName: "Nombre",
    lastName: "Apellido",
    emailRule: "El correo electronico debe ser valido",
    phoneRule: "El número de teléfono debe tener 10 dígitos",
    userExists: "Usuario registrado anteriormente",
    emailUsed: "Correo electrónico en uso. Por favor, entre uno nuevo",
    personalPhoneUsed:
      "Número de teléfono personal en uso. Por favor, entre uno nuevo",
    sharkEncounter: "Encuentro con tiburones",
    middleName: "Segundo Nombre",
    memberId: "ID de miembro",
    setMarkerHint: "Click en Ubicar Marcador para confirmar la ubicación",
    setLocationHint:
      "Según su selección en la página anterior, elija la ubicación más cercana en el menú desplegable o ingrese una nueva ubicación para revisión y aprobación",
    warningNote:
      "Por favor, complete la mayor cantidad de información posible. Como mínimo, cada recaptura devuelta debe tener una especie, fecha, ubicación/GPS y al menos un miembro del equipo de pesca listado. Si falta alguna de esta información, el informe no es utilizable para fines de investigación",
    noRepeatPriorTag: "El número ya ha sido utilizado como etiqueta anterior",
    noSeawaterSurface:
      "Coordenadas inválidas, no es una ubicación en la superficie del agua",
    invalid: "Información inválida",
    invalidCoords: "Coordenadas inválidas",
    invalidDate: "Fecha inválida",
    coordinates: "COORDENADAS",
    recordExists:
      "El registro que intentas guardar ya existe. Por favor, verifica la información",
  },
  recordFish: {
    warning:
      "¿Registraste el número rojo de seis dígitos de la tarjeta de liberación en la sección de notas?",
    needFulfill: "Algunos campos deben ser llenados",
    tagExists:
      "El número de etiqueta que intentas guardar ya existe. Por favor, use otro",
    releaseExists: "El registro que intentas guardar ya existe",
    recaptureExists:
      "Hay un registro de recaptura con el mismo número de etiqueta anterior. Por favor, cámbialo",
    addedForReview: "Record added for review",
    releaseExistsConfirmationTitle:
      "Hay un registro de liberación con los mismos datos que estás intentando guardar. ¿Quieres continuar?",
    releaseDuplicatedMessage:
      "El registro de liberación será marcado como duplicado y será enviado a un administrador para su aprobación",
    releaseExistsConfirmationNo: "No, lo modificaré",
    releaseExistsConfirmationYes: "Sí, quiero continuar",
  },
  newLocation: {
    title: "Nueva Localización",
    location: "NOMBRE DEL LUGAR",
    created: "Se ha registrado correctamente",
    failed: "La localiación ya existe o la operación falló",
  },
  batchReview: {
    clear: "Limpiar tablero",
    askAction: "¿Estás seguro de proceder?",
    addRecord: "AÑADIR REGISTRO",
    clickOn: "Click en el botón",
    toAdd: "para añadir registros al tablero",
    added: "Añadido para revisión",
    addMore: "Agregar más",
    addRecords: "Añadir Registros",
    editRecords: "Editar Registros",
  },
  search: {
    title: "Página de búsqueda",
    searchFor: "BUSCAR POR",
    species: "ESPECIES",
    ocean: "OCÉANO",
    number: "ETIQUETA",
    priorNumber: "ETIQUETA ANTERIOR",
    angler: "PESCADOR",
    captain: "CAPITÁN",
    firstMate: "PRIMER OFICIAL",
    secondMate: "SEGUNDO OFICIAL",
    user: "USUARIO",
    boat: "BOTE",
    catchStartDate: "FECHA INICIAL DE CAPTURA",
    catchEndDate: "FECHA FINAL DE CAPTURA",
    location: "LUGAR",
    hook: "ANZUELO",
    enteredBy: "ENTRADO POR",
    enteredByStartDate: "FECHA INICIAL DE ENTRADA",
    enteredByEndDate: "FECHA FINAL DE ENTRADA",
    searchBtn: "BUSCAR",
    tags: "ETIQUETAS",
    releases: "DEVOLUCIONES",
    recaptures: "RECAPTURAS",
    all: "TODO",
    foundText: "Encontrados",
    records: "registros",
    resetBtn: "resetear",
    back: "Atrás",
    actions: "",
    tagDate: "FECHA DE ETIQUETA/DEVOLUCIÓN",
    enteredDate: "FECHA INGRESADA",
    tableTitle: "Resultados de la búsqueda",
    preview: "Avance",
  },
  recaptureMail: {
    subject: "Nueva Recaptura",
    body: `
    Número de etiqueta: \n
    Océano: \n
    Especies: \n
    Fecha de recaptura: \n
    Descripción del lugar (coordenadas si está disponible): \n
    \n
    Largo del pez: \n
    Determinación del tamaño (medido o estimado): \n
    Peso del pez: \n
    Determinación del peso (medido o estimado): \n
    Horas de lucha: \n
    Minutos de lucha: \n
    \n
    Condición del pez al momento de la liberación (excelente, bueno, prometedor, muerto): \n
    Tipo de carnada (Viva, Muerta o Artificial): \n
    Tipo de equipo: \n
    Tipo de anzuelo (circular o anzuelo J): \n
    \n
    Si volvió a etiquetar al pez, nuevo número de etiqueta: \n     
    \n
    Nombre del bote: \n
    Encuentro con tiburones (Sí / No): \n
  
    - Pescador - \n
    Nombre: \n
    Talla de playera: \n
    Dirección: \n
    Correo Electrónico: \n
  
    - Capitán - \n
    Nombre: \n
    Talla de playera: \n
    Dirección: \n
    Correo Electrónico: \n
  
    - Primer Oficial - \n
    Nombre: \n
    Talla de playera: \n
    Dirección: \n
    Correo Electrónico: \n
  
    - Segundo oficial - \n
    Nombre: \n
    Talla de playera: \n
    Dirección: \n
    Correo Electrónico: \n
    `,
  },
  species: {
    blackMarlin: "MARLIN NEGRO",
    blueMarlin: "MARLIN AZUL",
    sailfish: "PEZ VELA",
    spearfish: "PEZ AGUJA",
    stripedMarlin: "MARLIN RAYADO",
    whiteMarlin: "MARLIN BLANCO",
    swordfish: "PEZ ESPADA",
    bluefinTuna: "ATUN DE ALETA AZUL",
    yellowfinTuna: "ATUN DE ALETA AMARILLA",
    albacoreTuna: "ATUN ALBACORA",
  },
  baits: {
    artificial: "ARTIFICIAL",
    dead: "MUERTO",
    fly: "MOSCA",
    live: "VIVO",
    other: "OTRA",
    combination: "COMBINACIÓN",
    unknown: "DESCONOCIDA",
  },
  competitions: {
    international: "INTERNACIONAL",
    southFlorida: "SUR DE LA FLORIDA",
    gulfOfMexico: "GOLFO DE MÉXICO",
    capeVerde: "CABO VERDE",
    hawaii: "HAWAII",
  },
  gears: {
    baitBoat: "BARCO DE CEBO",
    beachSeine: "ATARRAYA",
    gillNet: "RED DE ENMALLE",
    handLine: "SEDAL",
    harpoon: "ARPÓN",
    jigLine: "SEÑUELO",
    longLine: "PALANGRE",
    purseSeine: "CERCO CON JARETA",
    rodAndReel: "VARA Y CARRETE",
    spear: "LANZA",
    trammelNet: "RED DE TRASMALLO",
    trapNet: "RED DE TRAMPA",
    trawl: "RED DE ARRASTRE",
    greenStick: "PALO VERDE",
    other: "OTRO",
  },
  length: {
    unit: {
      inches: "PULGADAS",
      feet: "PIES",
      centimeters: "CENTÍMETROS",
      meters: "METROS",
      unknown: "DESCONOCIDO",
    },
    type: {
      estimated: "ESTIMADO",
      eyefork: "LONGITUD DE HORQUILLA",
      forkLength: "LONGITUD DE TENEDOR",
      jawfork: "LONGITUD MANDIBULAR",
      total: "TAMAÑO TOTAL",
      unknown: "DESCONOCIDO",
    },
  },
  weight: {
    unit: {
      kg: "KILOGRAMOS",
      lb: "LIBRAS",
      unknown: "DESCONOCIDO",
    },
    type: {
      dressed: "EN CANAL",
      estimated: "ESTIMADO",
      round: "APROXIMADO",
      whole: "TODO",
      unknown: "DESCONOCIDO",
    },
  },
  determination: {
    estimated: "ESTIMADO",
    measured: "MEDIDO",
    unknown: "DESCONOCIDO",
  },
  recaptcha: {
    failedValidation:
      "Operación fallida durante la validación del captcha, pruebe de nuevo",
    failedService:
      "El servicio de validación del captcha ha fallado, por favor contacte al equipo de desarrollo de Billfish",
    errorCodesIntro: "Los códigos de error son los siguientes",
    failed3Times:
      "Has fallado la validación del captcha tres veces seguidas, no podemos identificarte como humano. Necesitas refrescar el sitio para continuar con el proceso de inicio de sesión",
  },
  rewardSolicitation: {
    title: "Recompensa de TBF",
    tbfMsg:
      "Gracias por tu participación en el Programa de Marcado y Liberación de TBF. Como agradecimiento por tu participación, te ofrecemos una camiseta y un certificado de participación. Por favor, selecciona tus preferencias a continuación:",
    shirtSizeQuestion: "¿Cuál es tu talla de camiseta?",
    shirtSize: "Talla",
    rewardNotification:
      "Si no desea recibir ninguna recompensa, incluido el certificado y el informe, indíquelo aquí:",
    userNotification:
      "Puede modificar estas opciones en el perfil de su cuenta en cualquier momento",
    wantIt: "Quiero recibir la camiseta y el certificado",
    receiveCertificate: "Solo quiero recibir el certificado",
    receiveReward: "Solo quiero recibir la camiseta",
    doNotWantIt: "No quiero recibir la camiseta ni el certificado",
    address: "Dirección?",
    confirmationTitle: "¿Estás seguro?",
    confirmationSubtitle: "¿Quieres eliminarlo?",
    confirmationOk: "Sí, elimínalo",
    confirmationCancel: "No, mantenlo",
    singleLocationTitle: "¿Enviar todas las recompensas a una sola dirección?",
    singleLocationTooltip:
      "Entendemos que es posible que desee simplificar el proceso de recibir sus recompensas. Para facilitar esto, ofrecemos la opción de enviar todas las recompensas a una sola dirección. Tenga en cuenta que al elegir esta opción, está confirmando que la dirección proporcionada recibirá las recompensas de todos los destinatarios. Asegúrese de que la dirección sea precisa y accesible para evitar retrasos o envíos perdidos.",
    singleLocationLabel: "Dirección?",
    recipientCountWarning:
      "Es necesario agregar/activar uno o más destinatarios para enviar las recompensas. Si no desea recibir ninguna recompensa, descarte este formulario haciendo clic en la 'X' en la esquina superior izquierda.",
    recipientSubmit: "Haga clic aquí para completar",
    recipientAction: "Haga clic aquí para tomar acción",
    recipientSubmitted: "Completado",
    recipientDiscarded: "Descartado",
    recipientAddName: "Agregar nombre del destinatario",
    recipientHint:
      "Haga clic en la casilla para activar el destinatario. Una vez activado, puede agregar los detalles de la recompensa y enviar el formulario.",
    recipientAddressTooltip:
      "Asegúrese de que la dirección sea precisa y accesible para evitar retrasos o envíos perdidos.",
    addRecipient: "Agregar nuevo destinatario",
    addRecipientWarning: "Por favor complete el destinatario actual",
    submitFailed: "Error al enviar recompensas",
    socialMediaQuestion:
      "Si publicamos esta recaptura en nuestras plataformas de redes sociales, ¿hay alguien a quien le gustaría que etiquetemos? Proporcione cualquier identificador a continuación, separado por una coma",
  },
  batchFeature: {
    applyHint:
      "Use esta función para seleccionar qué parámetros se utilizarán de este registro para ser actualizados en todos los registros. Después de la validación del registro, todos los parámetros seleccionados se aplicarán al lote.",
    apply: "Aplicar al lote",
    applyLocation: "Aplicar coordenadas, descripción del lugar y océano",
    applyBoat: "Aplicar nombre del bote",
    applyAngler: "Aplicar pescador",
    applyCaptain: "Aplicar capitán",
    applyFirstMate: "Aplicar primer oficial",
    applySecondMate: "Aplicar segundo oficial",
    applyTournament: "Aplicar torneo",
    applyHook: "Aplicar tipo de anzuelo",
    applyBait: "Aplicar tipo de carnada",
    applyGear: "Aplicar tipo de equipo",
    applySpecies: "Aplicar especies",
    uploadHint:
      "Suba el archivo arrastrándolo y soltándolo en el área punteada o haga clic en el botón de abajo",
    upload: "Subir archivo",
    rememberDownload: "Recuerde descargar la plantilla más reciente",
    template: "Plantilla",
    recommended:
      "Microsoft Excel es recomendado para la correcta visualización y edición de los datos",
    title: "Importación masiva",
    total: "Total",
    valid: "Válidos",
    invalid: "Inválidos",
    clickToEdit: "Haga clic para editar",
    unsupportedFile:
      "Tipo de archivo no soportado, solo se permiten archivos de Excel",
    unableToReadFile: {
      title: "Error al importar el archivo",
      message: "Descargue y use la última plantilla \n {error}",
    },
    unableToBuildTemplateEs: "Error al crear la plantilla",
    noRecordsFound: "No se encontraron registros",
    invalidRecord: "Registro inválido, por favor revise",
    invalidRecords: "Algunos registros son inválidos, por favor revise",
    duplicatedTagNumber: "Número de etiqueta duplicado",
    recordsRegisteredTitle: "Completado!",
    recordsRegisteredSubtitle: "Todos los registros fueron cargados",
    recordsNotRegisteredTitle: "Error!",
    recordsNotRegisteredSubtitle: "Los registros no pudieron ser cargados",
    clearDataSubtitle:
      "¿Estás seguro de que deseas borrar el lote cargado? Esta acción no se puede deshacer",
    clearDataTitle: "Borrar lote",
    proceed: "Proceder",
    deleteConfirmation: "¿Estás seguro de proceder? Esto eliminará el registro",
    deleteRecord: "Eliminar registro",
  },
  confirmation: "Confirmación",
  email: "Correo electrónico",
  password: "Contraseña",
  privacy: "Privacidad",
  close: "Cerrar",
  cancel: "Cancelar",
  save: "Guardar",
  saving: "Guardando",
  submit: "Enviar",
  validate: "Validar",
  tags: "Etiquetas",
  releases: "Devoluciones",
  recaptures: "Recapturas",
  juveniles: "Peces Espada Jóvenes",
  noRecords: "Registros no encontrados",
  noAddress: "Dirección no registrada",
  new: "Nuevo",
  edit: "Editar",
  failed: "Operación fallida",
  failedCaptcha:
    "Operación fallida durante la validación del captcha, pruebe de nuevo",
  notSpecified: "No especificado",
  loading: "Loading",
  registered: "Registrado",
  updated: "Actualizado",
  created: "Creado",
  clear: "Borrar",
  finish: "Finalizar",
  tutorials: "Video Tutoriales",
  profile: "Perfil",
  logout: "Cerrar sesión",
  back: "Atrás",
  saveNew: "Salvar & Nuevo",
  saveCopy: "Salvar & Copiar",
  update: "Actualizar",
  apply: "Aplicar",
  load: "Cargar",
  replace: "Reemplazar",
  loadImage: "Cargar Imagen",
  draft: "Borrador",
  bindWarning: {
    text: "Tu perfil ha sido vinculado a tu membresía TBF #{member}, actualice su información {url}. Si necesitas ayuda, {mail}!",
    link: "aquí",
    contact: "contáctanos",
  },
  next: "Siguiente",
  help: "Ayuda",
  date: "FECHA",
  unknown: "DESCONOCIDO",
  yes: "Sí",
  no: "No",
  warning: "Advertencia",
};
export default es;
